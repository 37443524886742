import React from 'react';
// import Particles from 'react-tsparticles';
// import ParticleConfig from './config/particle-config.js';

export default function ParticleBackground() {
  return (
    <div className="particles">
      {/* <Particles params={ParticleConfig}></Particles> */}
    </div>
  );
}
